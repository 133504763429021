const setFocus = (): void => {
    const header: HTMLElement = document.querySelector('#globalHeader') as HTMLElement;
    header && header.focus();
};

// eslint-disable-next-line @typescript-eslint/ban-types
const pageScrollAnimate = (destination: number | HTMLElement, callback: Function): void => {
    const documentHeight: number = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
    );
    const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset,
    );

    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
    }

    const scroll = () => {
        const fixedOffset = destinationOffsetToScroll.toFixed();
        const onScroll = () => {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll);
                setFocus();
            }
        };

        window.addEventListener('scroll', onScroll);

        onScroll();

        window.scroll({
            top: 0,
            behavior: 'smooth',
        });
    };
    scroll();
};

export const scrollToGlobalHeader = (event: MouseEvent): void => {
    event.preventDefault();

    pageScrollAnimate(0, setFocus);
};
