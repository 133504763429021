import '@atlas/levity';

import { get, set, isEmpty } from 'lodash';

if (isEmpty(get(window, 'cb.core.utils.DeploymentProfile'))) {
    set(window, 'cb.core.utils.DeploymentProfile', {
        iam: {
            aws: {},
        },
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (window as any).cb.core.iam;
