/**
 * @file Defines the Search web component used by the global header.
 * @author Motti Horesh <mhoresh@collegeboard.org>
 */

import { template, suggestionItem } from './search.template';
import searchStyles from './search.scss?inline';
import stringReplacer from '../../../../core/src/ts/stringReplacer';

/**
 * Basic structure for the suggestions items.
 *
 * All API calls should map the data to this format.
 */
type SuggestionItem = {
    id: string;
    label: string;
    value: string;
};

/**
 * Defines the available types of options that are accepted for the component.
 */
type SearchOptions = {
    sensitivity: number;
    limit: number;
    siteType: string;
    searchType: string;
    destinationURL: string;
    typeAheadURL: string;
};

class Search extends HTMLElement {
    suggestions: SuggestionItem[] = [];
    options: SearchOptions = {
        sensitivity: 2,
        limit: 10,
        siteType: 'site',
        searchType: 'k-12',
        destinationURL: 'https://searchresults.collegeboard.org/cb/index.html',
        typeAheadURL: 'https://searchresults.collegeboard.org/rest/v2/api/SmartSuggest',
    };

    suggestionList!: HTMLElement;
    currentElement: HTMLElement | null = null;
    searchInput!: HTMLInputElement;
    searchForm!: HTMLFormElement;
    suggestionListStatus!: HTMLElement;

    constructor() {
        super();
    }

    /**
     * Called when the web component is ready and mounted.
     *
     * @returns void
     */
    connectedCallback() {
        this.updateOptions();

        // For accessibility, we need different IDs for mobile vs. desktop search.
        const parent = this.parentElement;
        const searchListId = parent?.classList?.contains('cbw-mobile-search-body')
            ? 'cbwHeaderSearchList-mobile'
            : 'cbwHeaderSearchList';

        // Update component markup.
        const replacements: { [key: string]: string } = {
            styles: searchStyles.toString(),
            searchURL: this.options.destinationURL,
            searchType: this.options.searchType,
            siteType: this.options.siteType,
            searchListId: searchListId,
        };

        this.innerHTML = stringReplacer(template, replacements);

        // Initialize class properties.
        this.suggestionList = this.querySelector('.cbw-search-suggestions') as HTMLElement;
        this.suggestionListStatus = this.querySelector('.cbw-search-suggestions-status') as HTMLElement;
        this.searchInput = this.querySelector('.cbw-input') as HTMLInputElement;
        this.searchForm = this.querySelector('form') as HTMLFormElement;

        // Global event listeners.
        this.suggestionList?.addEventListener('keydown', this.suggestionsKeyDownHandler.bind(this));
        this.suggestionList?.addEventListener('click', this.suggestionClickHandler.bind(this));
        this.searchInput?.addEventListener('keydown', this.searchInputKeyDownHandler.bind(this));
        this.searchInput?.addEventListener('keyup', this.searchInputKeyUpHandler.bind(this));
    }

    /**
     * Called when the web component unmounts - cleanup.
     *
     * @returns void
     */
    disconnectedCallback() {
        this.suggestionList?.removeEventListener('keydown', this.suggestionsKeyDownHandler);
        this.suggestionList?.removeEventListener('click', this.suggestionClickHandler);
        this.searchInput?.removeEventListener('keydown', this.searchInputKeyDownHandler);
        this.searchInput?.removeEventListener('keyup', this.searchInputKeyUpHandler);
    }

    /**
     * Parses provided attributes and update options.
     *
     * @returns void
     */
    updateOptions() {
        if (this.getAttribute('sensitivity') !== null) {
            this.options.sensitivity = parseInt(this.getAttribute('sensitivity') as string);
        }

        if (this.getAttribute('limit') !== null) {
            this.options.limit = parseInt(this.getAttribute('limit') as string);
        }

        if (this.getAttribute('site-type') !== null) {
            this.options.siteType = this.getAttribute('site-type') as string;
        }

        if (this.getAttribute('search-type') !== null) {
            this.options.searchType = this.getAttribute('search-type') as string;
        }

        if (this.getAttribute('destination-url') !== null) {
            this.options.destinationURL = this.getAttribute('destination-url') as string;
        }

        if (this.getAttribute('type-ahead-url') !== null) {
            this.options.typeAheadURL = this.getAttribute('type-ahead-url') as string;
        }
    }

    /***************************
     *       Auto Complete     *
     * *************************/
    populateSuggestions() {
        for (const suggestion of this.suggestions) {
            const template = stringReplacer(suggestionItem, suggestion);
            this.suggestionList.insertAdjacentHTML('beforeend', template);
        }
        document.addEventListener('mouseup', this.windowClickHandler.bind(this));
    }

    /**
     * Fetches autocomplete items through API, update the list of suggestions.
     *
     * @param term string
     *    Search term.
     *
     * @returns void
     */
    fetchSuggestions(term: string) {
        const url = new URL(this.options.typeAheadURL);
        url.searchParams.append('q', term);
        url.searchParams.append('lang', 'en');
        url.searchParams.append('cname', 'store');
        url.searchParams.append('limit', this.options.limit.toString());

        fetch(url.href)
            .then(res => {
                res.json()
                    .then(data => {
                        this.clearSuggestions();
                        for (const index in data[0]) {
                            this.suggestions.push({
                                id: index,
                                label: data[0][index],
                                value: data[0][index].replace(/ /g, '-'),
                            });
                        }

                        if (this.suggestions.length > 0) {
                            this.populateSuggestions();
                            this.suggestionListStatus.innerHTML = `${this.suggestions.length.toString()} ${
                                this.suggestions.length === 1 ? 'suggestion' : 'suggestions'
                            } available`;
                        } else {
                            this.suggestionListStatus.innerHTML = 'no suggestions available';
                        }
                    })
                    .catch(error => {
                        // Unable to parse json catch block.
                        console.error(error);
                    });
            })
            .catch(error => {
                // Critical fetch error catch block.
                console.error(error);
            });
    }

    /**
     * Handles accessible and focus management for dropdown items.
     *
     * @param element HTMLElement
     *    Target element.
     *
     *  @returns void
     */
    suggestionUpdateFocus(element: HTMLElement) {
        element.setAttribute('aria-selected', 'true');

        this.currentElement && this.currentElement.setAttribute('aria-selected', 'false');
        this.currentElement = element;

        element.focus();
    }

    /***
     * Clears all properties and attributes related to the auto complete lookup.
     *
     * @returns void
     */
    clearSuggestions() {
        this.searchInput?.focus();
        this.suggestionList.innerHTML = '';
        this.suggestionListStatus.innerHTML = 'no suggestions available';

        this.suggestions = [];
        this.currentElement = null;

        // Clear aria labels for the suggestion list.
        this.searchInput.setAttribute('aria-activedescendant', '');
        this.searchInput.setAttribute('aria-describedby', '');

        document.removeEventListener('mousedown', this.windowClickHandler);
    }

    /**** Handlers ****/
    /**
     * Update search query and submits the form.
     *
     * @param event Event
     *   Click Event
     */
    suggestionClickHandler(event: Event) {
        const targetElement = event.target as HTMLElement;

        this.searchInput.value = targetElement.innerText;
        this.searchForm.submit();
    }

    /**
     * Handles key events for the suggestion list.
     *
     * @param event KeyboardEvent
     *
     *  @returns void
     */
    suggestionsKeyDownHandler(event: KeyboardEvent) {
        event.preventDefault();
        const { key, shiftKey } = event;
        const target = event.target as HTMLElement;

        if (key === 'ArrowDown' || (key === 'Tab' && !shiftKey)) {
            this.suggestionUpdateFocus(
                (target.nextElementSibling as HTMLElement) || (target.parentElement?.firstElementChild as HTMLElement),
            );
        } else if (key === 'ArrowUp' || (key === 'Tab' && shiftKey)) {
            this.suggestionUpdateFocus(
                (target.previousElementSibling as HTMLElement) ||
                    (target.parentElement?.lastElementChild as HTMLElement),
            );
        } else if (key === 'Escape') {
            this.clearSuggestions();
        } else if (key === 'Enter') {
            this.currentElement?.click();
        }
    }

    /**
     * Handles keyboard events on the search input field.
     *
     * @param key KeyboardEvent
     *
     * @return void
     */
    searchInputKeyDownHandler({ key }: KeyboardEvent) {
        if (key === 'Tab') {
            this.clearSuggestions();
        }
    }

    /**
     * Handles keyboard events on the search input field.
     *
     * @param key KeyboardEvent
     *
     * @return void
     */
    searchInputKeyUpHandler({ key }: KeyboardEvent) {
        if (key === 'Escape') {
            this.clearSuggestions();
        } else if (key === 'ArrowDown') {
            if (this.suggestions.length) {
                const elm = this.suggestionList?.querySelector('li') as HTMLElement;
                elm.focus();
                this.suggestionList?.setAttribute('aria-activedescendant', elm?.getAttribute('id') as string);
            } else {
                this.fetchSuggestions(this.searchInput?.value);
            }
        } else {
            if (this.searchInput?.value.length >= this.options.sensitivity) {
                this.fetchSuggestions(this.searchInput?.value);
                // this.currentElement.setAttribute('aria-describedby', this.props.suggestionsListId);
            }
        }
    }

    /**
     * Window event listener to handle dismissal of suggestions list dialog.
     *
     * @param event Event
     *
     * @returns void
     */
    windowClickHandler(event: Event) {
        if (event.composedPath().indexOf(this.suggestionList) === -1) {
            this.clearSuggestions();
        }
    }
}

export default Search;
