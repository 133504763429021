import template from './notification.template';
import fontsStyle from '../../../../core/src/scss/fonts.scss?inline';
import notificationStyle from './notification.scss?inline';
import stringReplacer from '../../../../core/src/ts/stringReplacer';
import { shadowLinkCloner } from '../../../../core/src/ts/analytics/shadowLinkCloner';

import cookie from 'js-cookie';

class Notification extends HTMLElement {
    cookieName = 'lv-notificationWidget-storage-cookie';
    notificationDom: ShadowRoot;

    constructor() {
        super();
        this.notificationDom = this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        const shadowDOM = this.shadowRoot;
        if (!shadowDOM) {
            return;
        }

        const replacements: { [key: string]: string } = {
            notificationStyle: notificationStyle.toString(),
            header: this.getAttribute('header') as string,
            message: this.innerHTML,
            icon: this.icon(this.getAttribute('type') ?? ''),
            type: this.ariaLabelType(this.getAttribute('type')),
            id: this.getAttribute('nid') as string,
        };
        shadowDOM.innerHTML = stringReplacer(template, replacements);

        // Remove header tag when not used.
        if (!replacements.header) {
            shadowDOM.querySelector('h2')?.remove();
        }

        // Attach click event
        const closeButton = this.shadowRoot?.querySelector('.cbw-notification-close button') as HTMLButtonElement;

        closeButton.addEventListener('click', () => {
            this.hideNotification(this);
        });

        // hide notification if it was previously closed
        const hiddenIDs = cookie.get(this.cookieName)?.split(',') ?? [];
        if (hiddenIDs.indexOf(this.getAttribute('nid') ?? '') != -1) {
            this.remove();
        }

        const stylesheet = document.createElement('style');
        stylesheet.textContent = fontsStyle.toString();
        this.appendChild(stylesheet);

        const linkElem = document.createElement('link');
        linkElem.setAttribute('rel', 'stylesheet');
        linkElem.setAttribute('href', '//atlas.collegeboard.org/apricot/prod/4.5.17/widgets.css');
        shadowDOM.appendChild(linkElem);
        shadowLinkCloner(this);
    }

    disconnectedCallback() {
        // Remove any event listeners
        this.removeEventListener('click', () => {
            this.hideNotification(this);
        });
    }

    hideNotification(notification: Notification) {
        // Update cookie
        const hiddenIDs: string[] = cookie.get(notification.cookieName)?.split(',') ?? [];
        hiddenIDs.push(notification.getAttribute('nid') ?? '');

        cookie.set(notification.cookieName, [...new Set(hiddenIDs)].toString());

        this.dispatchEvent(
            new CustomEvent('cbw-notification-hide', { bubbles: true, composed: true, detail: { id: 1 } }),
        );

        notification.classList.add('hiding');
        setTimeout(() => {
            notification.remove();
        }, 500);
    }

    icon = (type: string) => {
        if (type == 'lv-success') {
            return 'cbw-green3 cbw-check';
        }
        if (type == 'lv-emergency') {
            return 'cbw-red cbw-exclamation';
        }
        return 'cbw-blue5 cbw-megaphone';
    };

    ariaLabelType = (type: string | null) => {
        if (type == 'lv-success') {
            return 'Success';
        }
        if (type == 'lv-emergency') {
            return 'Error';
        }
        return 'Information';
    };
}

export default Notification;
