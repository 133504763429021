export default `
<style>{{headerStyle}}</style>
<div class="cbw-dimmer cbw-hidden-xs-up"></div>
<div id="globalHeader" role="banner" tabIndex="-1" class="cbw-widget cbw-header cbw-border-top-{{programBorderColor}} cbw-bg-{{programBgXsColor}}-xs-only cbw-white-xs-only" part="cbw-widget">
  <a class="cbw-skip" data-cbtrack-linktype="toggle" data-cbtrack-label="Skip to Content Link" href="#{{skipTargetId}}">
    Skip to Content
  </a>
  <div class="cbw-container cbw-container-header-main">
    <div class="cbw-header-container">
      <div class="cbw-header-container-lockup">
        <div class="cbw-header-panel-trigger">
          <a
            class="cbw-header-panel-trigger-link"
            href="#widget-menu-control"
            aria-expanded="false"
            aria-label="{{mainLogoText}} Global Navigation"
            data-cbtrack-linktype="toggle" 
            data-cbtrack-label="Toggle Global Nav Menu"
          >
            <div class="cbw-close-navigation cbw-hidden-xs-up">
              <i class="cbw-icon cbw-x-mark" aria-hidden="true"></i>
              <span class="sr-only" aria-hidden="true">
                Close Global Navigation Panel
              </span>
            </div>

            <div class="cbw-open-navigation cbw-hidden-sm-up">
              <i class="cbw-logo {{cbMobileTriggerClass}}" aria-hidden="true"></i>
              <span class="sr-only" aria-hidden="true">
                Open Global Navigation Panel
              </span>
            </div>

            <div class="cbw-open-navigation cbw-hidden-xs">
              <i class="cbw-icon cbw-down" aria-hidden="true"></i>
              <span class="sr-only" aria-hidden="true">
                Open Global Navigation Panel
              </span>
            </div>
          </a>
        </div>

        <div class="cbw-header-logos">
          <h1 role="presentation" data-testid="cbw-main-logo" class="cbw-main-logo cbw-acorn cbw-hidden-xs">
            <{{mainLogoTag}} {{mainLogoHref}} class="cbw-logo {{cbLogoClass}}" data-cbtrack-label="{{mainLogoText}}" data-cbtrack-linktype="nav">
              <span class="sr-only">{{mainLogoText}}</span>
            </{{mainLogoTag}}>
          </h1>
          <h1 role="presentation" class="cbw-main-logo-mobile {{mainLogoMobileCenteredClass}} cbw-hidden-sm-up {{mainLogoMobileHideClass}}">
            <{{mainLogoTag}} {{mainLogoHref}} class="cbw-logo {{mainLogoMobileClass}}" data-cbtrack-label="{{mainLogoText}}" data-cbtrack-linktype="nav">
              <span class="sr-only">{{mainLogoText}}</span>
            </{{mainLogoTag}}>
          </h1>
          <h2 role="presentation" class="cbw-local-logo">
            <a href="/" class="cbw-logo-label cbw-bg-{{programBgColor}} cbw-{{programFontColor}} {{iconLogoClasses}}" data-cbtrack-linktype="nav" data-cbtrack-label="{{logoLabel}}" aria-label="{{logoLabel}}" >
              <span class="{{logoLabelClasses}}">{{logoLabel}}</span>
            </a>
          </h2>
        </div>
      </div>

      <div class="cbw-header-container-login">
        <a class="cbw-close-mobile-search cbw-hidden-xs-up" aria-label="Close Search Panel" 
        href="#close-panel" data-cbtrack-linktype="toggle" data-cbtrack-label="Toggle Mobile Search Panel">
          <i aria-hidden="true" class="cbw-icon cbw-x-mark"></i>
        </a>
        <a class="cbw-close-login cbw-hidden-xs-up" aria-label="Close Login Panel" 
        href="#close-panel" data-cbtrack-linktype="toggle" data-cbtrack-label="Toggle Account Panel">
          <i aria-hidden="true" class="cbw-icon cbw-x-mark"></i>
        </a>
        <div class="cbw-login-container"></div>
        <div class="cbw-search-wrapper">
          <cbw-search class="cbw-hidden-xs" app-id="{{appId}}" search-type="{{searchType}}" site-type="{{siteType}}"></cbw-search>
          <a href="#" data-cbtrack-linktype="toggle" data-cbtrack-label="Toggle Mobile Search Panel" aria-label="Open Search Panel" aria-haspopup="dialog"
              class="cbw-visible-xs cbw-hidden-md cbw-hidden-sm cbw-hidden-lg cbw-hidden-sm-up control cbw-open-mobile-search">
              <span class="cbw-icon cbw-search"></span><span class="sr-only">Open Search Panel</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <cbw-slidedown active-class=""></cbw-slidedown>

</div>`;
